.tab-container {
    width: 100%;
    margin: 0 auto;
}

.tab-header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab-header button {
    padding: 10px 20px;
    cursor: pointer;
    background-color: var(--background-color-2);
    border: none;
    outline: none;
    transition: color 0.3s;
    color: var(--primary-text-color);
    font-size: 1.2rem;
}

.tab-header button:hover {
    color: var(--hover-color);
    text-decoration: underline;
}

.active-tab {
    color: var(--hover-color);
    font-weight: bold;
    text-decoration: underline;
}

.tab-content {
    padding: 20px;
}

.tabs-section h2 {
    color: var(--primary-text-color);
    font-family: var(--font-heading-family);
    font-size: 1.2rem;
}

.tabs-section p {
    color: var(--secondary-text-color);
    font-size: 0.9rem;
}

.tabcomponent-link {
    cursor: pointer;
    font-style: italic;
}

.tabcomponent-link:hover {
    text-decoration: underline;
}

@media (max-width: 550px) {

    .tab-container,
    .tabs-section {
        width: 100%;
        margin: 0 auto;
    }

    .tabs-section {
        margin: 0;
        padding: 0;
    }

    .tabs-section h2 {
        text-align: center;
        padding: 0;
    }

    .tabs-section p {
        text-align: left;
        padding: 0;
    }

    .tab-header {
        justify-content: center;
    }

    .tab-header button {
        margin: 0 10px;
        padding: 5px 10px;
    }

    .tab-content {
        padding: 0px;
    }
}