.main-checkout-products {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 10px;
    color: var(--primary-text-color);
    background-color: var(--navbar-background); 
    width: 100%;
    margin-top: 1.5%;
    position: sticky;
    top: 50px;
    right: 0;
    height: 100%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.sub-checkout-products{
    height: 16rem;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 1rem;
}

.single-product-info{
    margin-bottom: 0.5rem;
}

.sub-checkout-products::-webkit-scrollbar {
    width: 5px;
    background: #555;
}

.sub-checkout-products::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}


.checkout-products-information {
    display: flex;
    align-items: center;
    gap: 5px;
}

.checkout-products-img-quantity {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
}

.checkout-products-img-quantity span{
    border-radius: 100%;
    background-color: var(--hover-color);    
    margin-left: -16%;
    margin-top: -5%;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout-products-img-quantity img {
    width: 130px;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
}

.checkout-products-img-quantity span {
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-text-color);
}

.checkout-product-pricing {
    margin-top: 20px;
    width: 100%;
}

.checkout-product-price-div {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    color: var(--primary-text-color);
    margin-top: 0.25rem;
}

.checkout-product-price-div p,
.checkout-product-price-div h5 {
    margin: 0;
}

.checkout-products-title , .checkout-products-size , .checkout-products-color{
    font-size: 0.8em;   
}

.place-order-privacy-policy{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.privay-policy-btn{
    border: none;
    color: var(--primary-text-color);
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
}

.privay-policy-btn:hover{
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .main-checkout-products {
        width: 100%;
        height: 500px;
    }

    .sub-checkout-div{
        width: 100%;
    }

    .sub-checkout-products{
        height: 100%;
    }
}


