.main-our-best-seller-card {
    width: 250px;
    color: var(--primary-text-color);
}

.our-seller-img-div img {
    width: auto;
    max-width: 100%;
    margin: 0 auto;
    height: 250px;
    object-fit: fill;
}

.our-best-seller-title {
    font-size: 0.8em;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--font-heading-family);
}

.our-best-seller-price {
    font-size: 1.2em;
    text-align: center;
    font-weight: 700;
    margin-top: 0rem;
}

.our-best-seller-rating-stars {
    display: flex;
    justify-content: center;
    margin-top: -0.5rem;
}

.main-our-best-seller-card:hover {
    cursor: pointer;
}

/* .main-our-best-seller-card:hover .our-best-seller-title {
    color: var(--hover-color);
} */

.main-our-best-seller-card:hover .our-best-seller-title {
    text-decoration: underline;
}

.our-best-seller-title-stock{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.our-best-seller-card-price-with-discount-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1.25rem;
}

.card-without-discount-price-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1.25rem;
}


@media (max-width:850px) {
    .main-our-best-seller-card {
        width: 200px;
    }
}

@media (max-width:550px) {
    .main-our-best-seller-card {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media (min-width:1600px) {
    .main-our-best-seller-card {
        width: 300px;
    }
}