.salient-features {
    display: flex;
    justify-content: space-around;
    background-color: var(--background-color-2);
    padding: 2rem 2.5rem;
    margin-top: -1.75rem;
    font-family: var(--font-body-family);
}

.salient-feature-divs {
    display: flex;
    justify-content: space-between;
    border-right: 1px solid var(--grey-text-color);
    padding: 1rem;
}

.salient-feature-divs svg {
    margin-top: 1rem;
}

.salient-feature-icons {
    height: 50px;
    width: 50px;
    color: var(--hover-color);
}

.salient-feature-divs h4 {
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: 500;
    color: var(--hover-color);
    font-family: var(--font-heading-family);
}

.salient-feature-divs p {
    color: var(--grey-text-color);
    font-size: 1.1em;
}

.salient-text-div {
    margin-left: 1rem;
}

@media (max-width: 768px) {
    .salient-features {
        flex-direction: column;
        padding-top: 1rem;
    }

    .salient-feature-divs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 0rem;
        padding-top: 1.5rem;
        border: none;
        width: 100%;
    }

    .salient-feature-divs h4,
    .salient-feature-divs p {
        text-align: center;
    }

    .salient-text-div {
        margin-left: 0;
    }
}