.size-selector-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
  }
  
  .size-search-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    color: white;
    background-color: black;
    border: 2px solid white;
    border-radius: 5px;
  }
  
  .size-search-input::placeholder {
    color: gray;
  }
  
  .size-options-dropdown {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: black;
    border: 2px solid white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .size-options-dropdown option {
    background-color: black;
    color: white;
  }
  
  .size-options-dropdown:focus {
    outline: none;
    border-color: gray;
  }
  