.search-order-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-bottom: 10px;
    width: 100%;
}

.search-order-input{
    width: 220px;
    background-color: transparent;
    border: 1px solid var(--grey-text-color);
    height: 35px;
    padding-left: 0.5rem;
    color: var(--primary-text-color);
}

.search-order-input::placeholder{
    color : var(--grey-text-color);
}

