.carousel {
    position: relative;
    height: 100vh;
    overflow: hidden;
    font-family: var(--font-body-family);
    cursor: pointer;
}

.carousel-content {
    display: flex;
    transition: transform 0.7s ease-in-out;
    transform: translateX(calc(-100% * var(--current-slide)));
}

.carousel-image-container {
    min-width: 100%;
    height: 100vh;
    position: relative;
}

.carousel-image {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* object-fit: contain; */
    opacity: 0.7;
}

.carousel-text {
    position: absolute;
    top: 40%;
    left: 7%;
    padding: 10px;
    border-radius: 5px;
    width: 40%;
    box-sizing: border-box;
}

.carousel-text-2 {
    position: absolute;
    top: 40%;
    left: 45%;
    padding: 10px;
    border-radius: 5px;
    width: 40%;
    box-sizing: border-box;
}

.carousel-text h2,
.carousel-text-2 h2 {
    margin: 0;
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    color: var(--primary-text-color);
    left: 5%;
    font-family: var(--font-heading-family);
}

.carousel-text-center h2 {
    margin: 5rem;
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    color: var(--secondary-text-color);
    margin-bottom: 1rem;
    font-family: var(--font-heading-family);
}

.carousel-text p,
.carousel-text-center p {
    margin-top: 10px;
    font-size: 1em;
    color: var(--primary-text-color);
}

.carousel-text-center {
    position: absolute;
    top: 38%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.carousel-button {
    opacity: 0;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: transparent;
    /* color: white; */
    border: none;
    height: 35px;
    width: 35px;
    cursor: pointer;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 1.4rem;
    font-weight: bolder;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.carousel-button.prev {
    left: 20px;
    color: var(--hover-color);
    /* background-color: var(--secondary-text-color); */
}

.carousel-button.next {
    right: 20px;
    color: var(--hover-color);
    /* background-color: var(--secondary-text-color); */
}

.carousel:hover .carousel-button {
    transition: all ease-in-out 0.7s;
    opacity: 1;
}

.carousel-button:hover {
    background-color: var(--hover-color);
    color: var(--secondary-text-color);
}

.header-shopnow {
    height: 50px;
    width: 250px;
    background: linear-gradient(to bottom, var(--hover-color) 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: bottom;
    font-size: 17px;
    color: var(--hover-color);
    border: 2.5px solid var(--hover-color);
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 50px;
    transition: background-position 0.4s ease-in-out, color 0.4s ease-in-out;
    cursor: pointer;
}

.header-shopnow:hover {
    background-position: top;
    color: var(--secondary-text-color);
}

@media (min-width: 1400px) {

    .carousel-text h2,
    .carousel-text-2 h2 {
        font-size: 2em;
    }
}


@media (min-width:1600px) {
    .carousel-text {
        top: 40%;
        left: 7%;
    }

    .carousel-text-2 {
        top: 40%;
    }

    .carousel-text h2,
    .carousel-text-2 h2 {
        font-size: 3em;
    }

    .carousel-text-center {
        top: 40%;
    }

    .carousel-text-center h2 {
        font-size: 3em;
    }

}

@media (min-width:1900px) {
    .carousel-text {
        top: 48%;
    }

    .carousel-text-center {
        top: 42.5%;
    }
}


@media (max-width: 1024px) {
    .carousel-text {
        top: 47%;
        left: 7%;
    }

    .carousel-text-center {
        top: 46%;
    }

    .carousel-text-2 {
        width: 70%;
        left: 8%;
        top: 48%;
    }

    .carousel-text-2 h2,
    .carousel-text h2,
    .carousel-text-center h2 {
        font-size: 3rem;
    }
}


@media (max-width: 850px) {
    .carousel {
        height: 80vh;
    }

    .carousel-button {
        opacity: 1;
    }

    .carousel-image-container {
        height: 80vh;
        width: 100%;
    }

    .carousel-text {
        display: flex;
        justify-content: center;
        text-align: center;
        top: 35%;
        width: 60%;
        left: 7%;
    }

    .carousel-text-center {
        top: 35%;
    }

    .carousel-text-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65%;
        left: 7%;
        top: 35%;
    }

    .carousel-text h2,
    .carousel-text-2 h2 {
        font-size: 2.5em;
    }

    .carousel-text-center h2 {
        font-size: 2.5em;
    }

    .carousel-text p {
        font-size: 0.9em;
    }

    .carousel-button {
        font-size: 1em;
        position: absolute;
        top: 50%;
    }

    .carousel-button.prev {
        left: 10px;
    }

    .carousel-button.next {
        right: 10px;
    }
}

@media (max-width: 480px) {
    .carousel {
        height: 80vh;
    }

    .carousel-image-container {
        height: 80vh;
        width: 100%;
    }

    .carousel-image {
        width: 100%;
        height: 80vh;
    }

    .carousel-text,
    .carousel-text-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        top: 40%;
        width: 100%;
        left: 0;
        padding: 0 2rem;
    }

    .carousel-text-2 {
        top: 40%;
    }

    .carousel-text h2,
    .carousel-text-2 h2 {
        font-size: 1.7em;
        text-align: center;
        text-wrap: wrap;
        letter-spacing: normal;
    }

    .carousel-text-center h2 {
        font-size: 1.5em;
    }

    .carousel-text p {
        font-size: 0.8em;
    }

    .carousel-button {
        position: absolute;
        top: 50%;
    }

    .carousel-button.next {
        right: 5px;
    }

    .carousel-button.prev {
        left: 5px;
    }

    .carousel-text-center {
        position: absolute;
        top: 28%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .carousel-text-center button {
        width: 220px;
        height: 40px;
    }
}