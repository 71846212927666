.main-sizepage{
    padding-top: 7rem;
}

.size-heading{
    font-size: 2.5em;
    color: var(--primary-text-color);
    text-transform: uppercase;
    text-align: center;
}

.sizeChart-div{
    margin-bottom: 2.5rem;
}

.sizeChart-div img{
    width: 65%;
    height: auto;
    display: flex;
    justify-content: center;
    margin: 0 auto;
}
