* {
    box-sizing: border-box;
}

.main-contact-form {
    background-color: var(--navbar-background);
    color: var(--primary-text-color);
    padding: 2rem;
    padding-top: 5rem;
    padding-bottom: 4rem;
    font-family: var(--font-body-family);
}

.sub-div-contact-form {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.heading-form {
    width: 45%;
    padding: 3rem;
}

.main-contact-form h1 {
    text-align: left;
    font-size: 1.8em;
    font-weight: bold;
    font-family: var(--font-heading-family);
    text-transform: uppercase;
}

.contact-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
}

.contact-form-label {
    font-size: 0.9em;
    color: darkgrey;
}

.contact-form-input,
.contact-form-select {
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--grey-text-color);
    margin-bottom: 1rem;
    color: var(--grey-text-color);
    padding-left: 0.75rem;
    height: 40px;
    /* border-radius: 4px; */
    font-size: 1em;
}

.contact-form-input-required{
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--hover-color);
    margin-bottom: 1rem;
    color: var(--grey-text-color);
    padding-left: 0.5rem;
    height: 40px;
    /* border-radius: 4px; */
    font-size: 1em;
}

.contact-form-input-big {
    width: 100%;
    background-color: transparent;
    border: 1.5px solid var(--primary-text-color);
    margin-bottom: 1rem;
    color: var(--grey-text-color);
    padding-left: 0.5rem;
    height: 40px;
    /* border-radius: 4px; */
    font-size: 1em;
}

.contact-form-input::placeholder , .contact-form-input-big::placeholder , .contact-form-input-required::placeholder{
    color: var(--grey-text-color);
}


.contact-form-select-big {
    background-color: transparent;
    border: 1px solid var(--grey-text-color);
    margin-bottom: 1rem;
    color: var(--grey-text-color);
    padding-left: 0.75rem;
    height: 40px;
    /* border-radius: 4px; */
    font-size: 1em;
    width: 100%;
}

.contact-form-text-area {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border: none;
    border: 1px solid var(--grey-text-color);
    height: 50px;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    color: var(--grey-text-color);
    font-family: var(--font-body-family);
    font-size: 1em;
}

.dual-input-field {
    display: flex;
    gap: 10px;
    width: 100%;
}

.contact-form-button {
    background: linear-gradient(to bottom, var(--primary-text-color) 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: bottom;
    font-size: 17px;
    color: var(--primary-text-color);
    border: 2.5px solid var(--primary-text-color);
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-position 0.4s ease-in-out, color 0.4s ease-in-out;
    cursor: pointer;
    height: 40px;
    width: 170px;
    /* border-radius: 4px; */
}

.contact-form-button:hover {
    background-position: top;
    color: black;
}

.file-upload-button {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 260px;
    height: 40px;
    padding: 1rem;
    cursor: pointer;
    background-color: transparent;
    color: var(--grey-text-color);
    border: 1px solid var(--grey-text-color);
    /* border-radius: 4px; */
    font-size: 1em;
    font-weight: 500;
    transition: background-color 0.3s ease;
    margin-bottom: 15px;
}

.upload-icon {
    font-size: 18px;
}

input[type="file"] {
    display: none;
}

@media (max-width: 850px) {
    .dual-input-field {
        flex-direction: column;
    }

    .heading-form {
        width: 50%;
    }

    .contact-form-select-big{
        width: 100%;
    }


    .contact-form-text-area {
        width: 100%;
        /* max-width: 350px; */
    }

    .contact-form-select,
    .contact-form-input,
    .contact-form-select-big,
    .contact-form-input-big {
        max-width: 100%;
    }
}

@media (max-width: 550px) {

    .sub-div-contact-form {
        flex-direction: column;
        justify-content: center;
    }

    .heading-form {
        width: 100%;
        text-align: center;
    }

    .contact-form-text-area , .contact-form-select-big {
        width: 100%;
    }

    .contact-form-select,
    .contact-form-input,
    .contact-form-input-big,
    .contact-form-select-big {
        width:100%;
    }
}