.main-cart {
    background-color: var(--background-color-2);
    color: var(--secondary-text-color);
    padding: 10px;
    margin: 0 auto;
    font-family: var(--font-body-family);
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    height: 100%;
}

.main-cart-emtpy {
    background-color: var(--background-color-2);
    color: var(--secondary-text-color);
    padding: 20px;
    margin: 0 auto;
    font-family: var(--font-body-family);
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.main-cart-emtpy h1 {
    font-size: 1.4em;
    color: var(--grey-text-color);
    font-weight: 650;
    text-transform: uppercase;
    font-family: var(--font-heading-family);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    z-index: 1000;
}

@keyframes fadeIn {
    0% {
        transform: translateX(100%);
        /* opacity: 0;  */
    }

    100% {
        transform: translateX(0);
        /* opacity: 1;  */
    }
}

@keyframes fadeOut {
    0% {
        transform: translateX(0);
        /* opacity: 1;  */
    }

    100% {
        transform: translateX(100%);
        /* opacity: 0; */
    }
}



.modal-content {
    animation: fadeIn 0.3s ease-in;
}


.main-cart-closing {
    animation: fadeOut 0.3s ease-out;
}


.modal-content {
    background: var(--background-color-1);
    padding: 0px;
    border-radius: 8px;
    width: 100%;
    position: relative;
    z-index: 1000;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: var(--primary-text-color);
    cursor: pointer;
}

.close-modal:hover {
    color: var(--hover-color);
}

.sub-cart {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 0px;
    height: 55%;
    overflow-y: auto;
}

.sub-cart::-webkit-scrollbar {
    width: 5px;
    background: #555;
}

.sub-cart::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.cart-data {
    display: flex;
    align-items:center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    margin-bottom: 1rem;
}

.cart-img-div {
    max-width: 110px;
    height: 140px;
}

.cart-item-image {
    width: 110px;
   height:  150px;
   object-fit: fill;
}

.cart-heading {
    font-size: 1.5em;
    text-transform: capitalize;
    color: var(--primary-text-color);
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: var(--font-heading-family);
}

.cart-operations-cart-slider {
    display: flex;
    align-items: center;
}

.cart-operation-btn {
    height: 19px;
    width: 20px;
    font-weight: 600;
    background-color: transparent;
    color: var(--primary-text-color);
    border: 1px solid var(--primary-text-color);
    cursor: pointer;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.cart-operation-btn:hover {
    background-color: var(--hover-color);
}

.cart-quantity-cart {
    display: flex;
    align-items: center;
    gap: 15px;
}

.quantity-input-cart:disabled {
    background-color: transparent;
    border: none;
    border-top: 1px solid var(--primary-text-color);
    border-bottom: 1px solid var(--primary-text-color);
    text-align: center;
    color: var(--primary-text-color);
    width: 30px;
    height: 19px;
}

.remove-text {
    color: var(--secondary-text-color);
    cursor: pointer;
    font-size: 0.9em;
    margin-top: -0.5rem;
}

.remove-text:hover {
    text-decoration: underline;
}

.cart-item-data p {
    font-size: 0.8em;
    margin: 0.5rem 0;
}

.total-price {
    width: 100%;
    position: absolute;
    bottom :0;
    right: 0;
    border-top: 1px solid var(--primary-text-color);
    padding: 0.5rem;
    /* margin-bottom: 0.25rem; */
}

.total-price-shipping,
.total-price-all-order,
.checkout-price-all-order {
    display: flex;
    justify-content: space-between;
    /* justify-content: flex-start; */
    /* gap: 50%; */
    align-items: center;
    padding:0 0.5rem;
    width: 100%;
    margin-top: -0.75rem;
    font-size: 1rem;
}

.checkout-price-all-order {
    margin-top: -0.5rem;
}

.checkout-price-all-order p {
    color: var(--grey-text-color);
    font-size: 0.9em;
}

.total-price p {
    font-size: 0.9em;
}


.cart-item-title {
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 600;
    cursor: pointer;
}

.cart-item-title:hover {
    text-decoration: underline;
}

.clear-cart-btn {
    width: 100%;
    height: 30px;
    background-color: transparent;
    border: none;
    color: var(--secondary-text-color);
}

.clear-cart-btn:hover {
    text-decoration: underline;
    cursor: pointer;
}

.privacy-policy-clear-cart-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media (min-width:1500px) {
    .sub-cart {
        height: 450px;
    }
}

@media (max-width:1050px) {

    .main-cart,
    .main-cart-emtpy {
        width: 60%;
    }
}

@media (max-width:550px) {

    .main-cart,
    .main-cart-emtpy {
        width: 100%;
    }

    .checkout-price-all-order {
        width: 100%;
        gap: 20px;
    }

    .checkout-price-all-order button {
        width: 120px;
        font-size: 12px;
        padding: 0;
        line-height: 0;
        margin: 0;
        height: 35px;
    }

    .quantity-input-cart:disabled {
        width: 20px;
        font-size: 0.7em;
    }

    .sub-cart {
        height: 60%;
    }

}