footer {
  background-color: var(--navbar-background);
  color: var(--primary-text-color);
  padding: 1cap 0;
  font-family: var(--font-body-family);
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-column {
  flex: 1 1 200px;
  margin: 10px 20px;
  width: 20%;
  flex-grow: 1;
}

.footer-column-bigger {
  width: 30%;
  flex: 1 1 200px;
  margin: 10px 20px;
}

.newsletter-label {
  font-size: 0.8em;
  color: var(--grey-text-color);
}

.newsletter-subscribe-div input {
  color: var(--grey-text-color);
  font-size: 1em;
  height: 35px;
  background-color: transparent;
  border: 1px solid var(--grey-text-color);
  width: 220px;
  padding-left: 0.5rem;
  font-size: 0.9rem;
}

.social-media-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.social-media-icons svg {
  font-size: 2em;
  background-color: transparent;
}

.social-media-icons a {
  text-decoration: none;
  color: var(--primary-text-color);
}

.social-media-icons svg:hover {
  color: var(--hover-color);
  cursor: pointer;
}

.payment-methods-div {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.payment-methods-div img {
  width: 50px;
  height: 30px;
}

.footer-column h4,
.footer-column-bigger h4 {
  font-size: 18px;
  margin-bottom: 15px;
  cursor: pointer;
  font-family: var(--font-heading-family);
}

.footer-column ul,
.footer-column-bigger ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li,
.footer-column-bigger ul li {
  margin-bottom: 10px;
}

.footer-column ul li p,
.footer-column-bigger ul li p {
  color: var(--secondary-text-color);
  text-decoration: none;
  transition: color 0.3s;
}

.footer-column ul li p:hover {
  color: var(--hover-color);
  cursor: pointer;
}

.newsletter-subscribe-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-btn {
  width: 90px;
}

@media (max-width: 1050px) {}

@media (max-width: 850px) {
  .footer-container {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }

  .footer-column,
  .footer-column-bigger {
    flex: 1 1 calc(50% - 40px);
    margin: 10px 10px;
  }

  .newsletter-subscribe-div {
    justify-content: space-between;
  }

  .newsletter-subscribe-div input {
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
    width: 60%;
  }

  .submit-btn {
    width: 120px;
  }

}

@media (max-width:1000px) {
  .newsletter-subscribe-div input {
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
    width: 50%;
  }

  .submit-btn {
    width: 100px;
  }
}

@media (max-width: 550px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column,
  .footer-column-bigger {
    flex: 1 1 100%;
    text-align: center;
    margin: 10px 0;
    width: 90%;
  }

  .newsletter-subscribe-div {
    justify-content: center;
    gap: 10px;
    margin: 0 auto;
  }

  .newsletter-subscribe-div input {
    margin-top: 1rem;
    width: 50%;
  }

  .newsletter-subscribe-div button {
    margin-top: 0.5rem;
  }

  .payment-methods-div {
    justify-content: center;
  }

  .social-media-icons {
    justify-content: center;
  }
}