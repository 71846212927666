.main-shopnow {
    background-color: var(--background-color-2);
    margin-top: -2rem;
    padding-top: 2rem;
    font-family: var(--font-body-family);
    overflow: hidden;
    padding: 5rem;
}

.sub-shopnow {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.text-shopnow,
.img-shopnow {
    width: 45%;
}

.text-shopnow {
    color: var(--primary-text-color);
    text-align: left;
}

.shopnow-heading {
    text-align: center;
    text-transform: uppercase;
    font-size: 2.5em;
    font-weight: 700;
    color: var(--secondary-text-color);
    font-family: var(--font-heading-family);
}

.shopnow-bold-p {
    font-size: 1.4em;
    margin-top: -1rem;
    font-weight: 500;
    text-transform: uppercase;
}

.shopnow-p {
    width: 80%;
    color: var(--grey-text-color);
    font-size: 1.4em;
}

.jacket-img {
    width: 400px;
    height: auto;
}

.custom-arrow-shopnow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    color: var(--hover-color);
    border-radius: 100%;
    height: 30px;
    width: 30px;
    cursor: pointer;
    font-size: 1.4rem;
    /* background-color: rgba(255, 255, 255, 1); */
    transform: translateY(-50%);
    opacity: 0;
}

.main-shopnow:hover .custom-arrow-shopnow {
    opacity: 1;
    transition: all ease-in-out 0.7s;
}

.custom-arrow-shopnow:hover {
    background-color: var(--hover-color);
    color: var(--primary-text-color);
}

.next-arrow-shopnow {
    right: -40px;
}

.prev-arrow-shopnow {
    left: -40px;
}

/* .slider-div {
    width: 100%;
}

.slick-dots {
    margin: 0 auto !important;
} */

@media (max-width: 1050px) {
    .jacket-img {
        width: 365px;
        height: auto;
    }

    .sub-shopnow {
        gap: 5px;
    }
}

@media (max-width: 850px) {
    .sub-shopnow {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .text-shopnow {
        margin: 0 auto;
        width: 80%;
        margin-bottom: 1rem;
    }

    .img-shopnow {
        width: 80%;
        margin: 0 auto;
    }

    .slider-div {
        width: 100%;
    }

    .jacket-img {
        margin: 0 auto;
        width: 100%;
        height: auto;
    }

    .custom-arrow-shopnow {
        height: 35px;
        width: 35px;
        font-size: 1.2em;
        right: 5%;
        left: auto;
        top: 50%;
    }

    .next-arrow-shopnow {
        right: 0%;
    }

    .prev-arrow-shopnow {
        left: 0%;
    }

    .shopnow-bold-p {
        margin-top: 1rem;
    }

}

@media (max-width: 600px) {
    .shopnow-heading {
        font-size: 2em;
    }

    .shopnow-bold-p {
        font-size: 1.2em;
    }

    .shopnow-p {
        font-size: 1em;
    }

    .jacket-img {
        width: 100%;
    }

    .custom-arrow-shopnow {
        height: 30px;
        width: 30px;
        font-size: 1em;
        right: 5%;
        left: auto;
        top: 40%;
    }

    .next-arrow-shopnow {
        right: -20%;
    }

    .prev-arrow-shopnow {
        left: -20%;
    }


}

@media (max-width: 550px) {
    .main-shopnow {
        padding: 0;
        width: 100%;
        padding-bottom: 4rem;
        margin: 0 auto;
        padding-top: 1rem;
    }

    /* .slider-div{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    } */



    .img-shopnow {
        display: flex;
        justify-content: center;
    }

    .img-shopnow img {
        width: 250px;
    }

    .text-shopnow {
        text-align: center;
        width: 100%;
    }

    .shopnow-p {
        width: 95%;
        padding: 1rem;
    }

    .custom-arrow-shopnow {
        top: 45%;
    }

    .next-arrow-shopnow {
        right: 5%;
    }

    .prev-arrow-shopnow {
        left: 5%;
    }
}