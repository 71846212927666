/* Navbar container and general styles */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 35px 0px;
  position: fixed;
  top: 0;
  left: 0;
  color: var(--primary-text-color);
  z-index: 1000;
  width: 100%;
  transition: all 0.4s ease-out;
  font-family: var(--font-body-family);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
}

.navbar-color {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navbar-background);
  padding: 35px 0px;
  position: fixed;
  top: 0;
  left: 0;
  color: var(--primary-text-color);
  z-index: 1000;
  width: 100%;
  transition: all 0.4s ease-out;
  font-family: var(--font-body-family);
}

.navbar-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-family: var(--font-body-family);
  padding: 0 1rem;
}


.navbar-left,
.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-left {
  width: 1;
}

.menu-toggle {
  display: none;
}

.menu-items {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

.menu-items li {
  position: relative;
  padding: 0 5px;
}

.menu-items p {
  margin: 0;
  padding: 5px;
  cursor: pointer;
  font-size: 17px;
  transition: transform 0.3s ease;
}

.menu-items p:hover {
  animation: moveUpDown 0.6s ease-in-out;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}

.cart-number {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22.5px;
  width: 22.5px;
  border-radius: 100%;
  background-color: var(--hover-color);
  color: var(--primary-text-color);
}


/* .cart-number::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 30%;
  transform: translateX(-50%);
  transform: rotate(300deg);
  border-width: 7px;
  border-style: solid;
  border-color: red transparent transparent transparent;
} */

.cart-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
}

.menu-items p:hover,
.dropdown li:hover {
  color: var(--hover-color);
}

.dropdown-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  width: auto;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--navbar-toggle-menu-background);
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 10;
  padding: 1rem;
}

.dropdown-parent:hover .dropdown {
  display: block;
}

.dropdown li {
  padding: 4px;
  cursor: pointer;
}

.scrollable-dropdown {
  height: auto;
  width: 200px;
}

.drawer-dropdown p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.drawer-dropdown-items {
  display: none;
  flex-direction: column;
  padding-left: 15px;
}

.drawer-dropdown-items.open {
  display: flex;
}

.arrow {
  margin-left: 5px;
  height: 20px;
}

.logo-container {
  position: absolute;
  left: 50%;
  text-align: center;
  cursor: pointer;
}

.logo-container img,
.logo-container-responsiveness img {
  height: 80px;
  width: 80px;
  background: transparent;
}

.logo-container-responsiveness {
  display: none;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right>* {
  margin-left: 20px;
}

.navbar-right svg:hover {
  color: var(--hover-color);
}

/* Styles for search bar */
.search-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  padding: 25px 20px;
}

.search-bar input {
  width: 50%;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: transparent;
}

.search-close {
  position: absolute;
  top: 25px;
  right: 60px;
  font-size: 34px;
  background: none;
  border: none;
  cursor: pointer;
  color: #ccc;
}

.search-logo {
  font-size: 1.4em;
  font-weight: 600;
  color: var(--primary-text-color);
  margin-left: 1rem;
  cursor: pointer;
}

.search-logo:hover {
  color: var(--hover-color);
}

.drawer {
  display: none;
}

.drawer.open {
  left: 0;
}

.drawer-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-text-color);
}


.drawer-menu-items {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 60px;
}

.drawer-menu-items li {
  padding: 15px 20px;
  text-decoration: none;
  /* border-bottom: 1px solid #ccc; */
}

.drawer-menu-items p {
  margin: 0;
  font-size: 18px;
  cursor: pointer;
}

.drawer-menu-items p:hover {
  color: var(--hover-color);
}

.wishlist-logo,
.wishlist-cart,
.wishlist-search {
  cursor: pointer;
}

@media (max-width: 1025px) {

  .navbar-color,
  .navbar {
    padding: 0;
  }

  .navbar-color,
  .navbar {
    width: 100%;
  }

  .menu-toggle {
    display: block;
  }

  .drawer {
    display: block;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: var(--navbar-background);
    color: var(--primary-text-color);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease;
    z-index: 1001;
    display: flex;
    flex-direction: column;
  }

  .navbar-container {
    flex-direction: column;
    align-items: center;
  }

  .menu-items {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .menu-items.open {
    display: flex;
  }

  .logo-container {
    display: none;
  }

  .logo-container-responsiveness {
    display: block;
  }

  .navbar-right {
    margin-top: 5px;
    justify-content: space-between;
    width: 100%;
  }

  /* .navbar {
    padding: 8px;
  } */

  .menu-toggle {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
  }
}