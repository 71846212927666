.section h3{
    font-size: 1.4em;
    color: var(--primary-text-color);
    text-transform: uppercase;
}

.section h4{
    font-size: 1.2em;
    color: var(--primary-text-color);
    text-transform: uppercase;
}


