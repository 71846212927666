.main-checkout-delievery-form {
    width: 100%;
}

.checkout-form-heading {
    font-size: 1.2em;
    color: var(--primary-text-color);
    text-transform: uppercase;
    font-weight: 550;
}

.news-offer-email-div {
    display: flex;
    gap: 10px;
}

.error-message{
    color: red;
    font-size: 0.9em;
    text-transform: capitalize;
}



