.main-order-summary{
    padding-top: 7rem;
    width: 100%;
}

.sub-main-order-summary{
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin-bottom: 1rem;
}

.main-order-summary-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 6rem;
    height: 40vh;
    margin-bottom: 1rem;
}

.order-not-found-img {
    margin-top: 1rem;
    color: var(--grey-text-color);
    /* height: 100px;
    width: 100px; */
}

.order-summary-thankyou-main-div {
    padding: 1rem;
    width: 50%;
    height: 45rem;
    margin-bottom: 1rem;
}

.order-summary-products-main-div {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 10px;
    color: var(--primary-text-color);
    background-color: var(--navbar-background);
    width: 49%;
    margin-top: 1rem;
    margin-right: 1rem;
    height: 36.75rem;
    border-radius: 5px;
}

.order-summary-confirmed-div {
    margin: 0 auto;
    text-align: center;
    padding: 1rem;
    background-color: var(--navbar-background);
    border-radius: 5px;
}

.order-summary-confirmed-div h2 {
    font-size: 1.2em;
    font-family: var(--font-heading-family);
    font-weight: 600;
    color: var(--primary-text-color);
}

.order-summary-heading {
    font-size: 1.4em;
    font-family: var(--font-heading-family);
    font-weight: 600;
    text-transform: uppercase;
    color: var(--primary-text-color);
    text-align: center;
}

.order-summary-order-id {
    color: var(--primary-text-color);
}

.order-summary-btn {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.checkout-products-title {
    font-weight: 600;
}

.order-summary-sub-checkout-products {
    max-height: 50%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 1rem;
}

.order-summary-sub-checkout-products::-webkit-scrollbar {
    width: 5px;
    background: #555;
}

.order-summary-sub-checkout-products::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.order-summary-item-description {
    font-size: 0.8em;
}

.order-summary-checkout-product-pricing {
    padding-top: 6%;
}

.order-summary-order-details {
    background-color: var(--navbar-background);
    border-radius: 5px;
    padding: 1rem;
    margin-top: 1rem;
}

.order-summary-checkout-product-price-div {
    display: flex;
    justify-content: space-between;
}

.order-summary-order-details h2 {
    font-size: 1.2em;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--primary-text-color);
    text-align: center;
}

.sub-order-summary-order-details {
    display: flex;
    justify-content: space-between;
    color: var(--primary-text-color);
}

.order-summary-item-discount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: -1.5rem;
    font-size : 0.9rem
}

@media (max-width:950px) {
    .sub-main-order-summary {
        flex-direction: column;
    }

    .order-summary-thankyou-main-div {
        width: 100%;
        height: 100%;
    }

    .order-summary-products-main-div {
        width: 100%;
    }
}

@media (max-width:550px) {
    .sub-order-summary-order-details {
        flex-direction: column;
        justify-content: center;
    }

    .order-summary-products-main-div {
        height: 100%;
    }



}