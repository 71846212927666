/* Popup.css */
.popup-overlay {
    position: fixed;
    top: 20px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background: var(--navbar-background);
    color : var(--primary-text-color); 
    padding: 10px;
    border: 1px solid var(--grey-text-color);
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content-form{
    background: var(--navbar-background);
    color : var(--primary-text-color); 
    padding: 10px;
    border: 1px solid var(--grey-text-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 100px;
}

.popup-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--primary-text-color);
}

.popup-close-btn:hover{
    color: var(--hover-color);
}

.popup-inner {
    display: flex;
    align-items: center;
}

.popup-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 20px;
}

.popup-text {
    display: flex;
    flex-direction: column;
}

.popup-text h3 {
    margin: 0 0 10px 0;
}

.popup-text p {
    margin: 0;
}
