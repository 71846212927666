.main-our-best-sellers {
    background-color: var(--navbar-background);
    padding: 5rem;
    padding-bottom: 5rem;
    max-width: 100%;
}

.our-best-seller-text h2 {
    font-size: 1.4em;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-text-color);
    text-align: center;
    font-family: var(--font-heading-family);
}

.our-best-seller-btn {
    background-color: transparent;
    border-radius: 25px;
    border: 1px solid var(--grey-text-color);
    color: var(--primary-text-color);
    height: 35px;
    width: 120px;
    margin-left: 0.5rem;
    text-align: center;
    cursor: pointer;
}

.our-best-seller-btn:hover {
    color: var(--secondary-text-color);
    background-color: var(--hover-color);
    border: 1px solid var(--text-red-color);
    transition: all 0.5s ease-in-out;
}

.our-best-seller-btn.selected {
    color: var(--secondary-text-color);
    background-color: var(--hover-color);
    border: 1px solid var(--text-red-color);
}

.best-seller-slider {
    margin: 0 auto;
    position: relative;
}

.our-best-seller-width-half {
    width: 50% !important;
    margin: 0 auto !important;
}

.our-best-seller-width-full {
    width: 100% !important;
    margin: 0 auto !important;
}


.custom-arrow-our-best-seller {
    position: absolute;
    top: 35%;
    left: 100%;
    color: var(--hover-color);
    height: 35px;
    width: 35px;
    text-align: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    font-size: 1.4rem;
}

.main-our-best-sellers:hover .custom-arrow-our-best-seller {
    opacity: 1;
    transition: all ease-in-out 0.7s;
}

.custom-arrow-our-best-seller:hover {
    background-color: var(--hover-color);
    color: var(--primary-text-color);
}

.prev-arrow-our-best-seller {
    left: -45px;
}

.slick-dots li button:before {
    font-size: 8px;
    color: var(--grey-text-color);
    opacity: 0.7;
    margin-top: 0.75rem;
}

.slick-dots li.slick-active button:before {
    color: var(--primary-text-color);
}

/* .slick-slide {
    width: calc(100% / 2 - 10px);
} */

@media (max-width: 1025px) {
    .our-best-seller-width-half {
        width: 64% !important;
    }
}

@media (max-width:850px) {
    .main-our-best-sellers {
        padding: 3rem;
        padding-bottom: 5rem;
    }

    .best-seller-slider {
        width: 100%;
    }

    .our-best-seller-width-half {
        width: 65% !important;
        margin: 0 auto !important;
    }
}

@media (max-width:700px) {
    .main-our-best-sellers {
        padding: 4.5rem;
    }

    .our-best-seller-width-half {
        width: 100% !important;
        margin: 0 auto !important;
    }
}

@media (max-width : 650px) {
    .main-our-best-sellers {
        padding: 3.5rem;
    }

    .prev-arrow-our-best-seller {
        left: -40px;
    }
}

@media (max-width:550px) {

    .main-our-best-sellers {
        padding: 3.5rem;
        padding-bottom: 5rem;
    }

    .custom-arrow-our-best-seller {
        top: 25%;
    }

    .best-seller-slider {
        width: 100%;
    }
}