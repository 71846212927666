.main-popup-form-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
}

.popup-form-content {
    background: var(--navbar-background);
    color: var(--primary-text-color);
    padding: 20px;
    border: 1px solid var(--grey-text-color);
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* border-radius: 10px;  */
}

.popup-form-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--primary-text-color);
}

.popup-form-close-btn:hover {
    color: var(--hover-color);
}

.popup-form-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.popup-form-text h1 {
    font-size: 1.4em;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: var(--primary-text-color);
    margin-top: 1rem;
}

.popup-form-text p {
    color: var(--primary-text-color);
    margin-bottom: 20px;
}
