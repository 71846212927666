.btn-main button{
    padding: 0.75rem 2rem;
    background: linear-gradient(to bottom, var(--hover-color) 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: bottom;
    font-size: 17px;
    color: var(--hover-color);
    border: 2.5px solid var(--hover-color);
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-position 0.4s ease-in-out, color 0.4s ease-in-out;
    cursor: pointer;
}

.btn-main button:disabled{
    cursor:not-allowed;
    border: 2.5px solid var(--grey-text-color);
    background: linear-gradient(to bottom, gray 50%, transparent 50%);
    background-size: 100% 200%;
    color:var(--grey-text-color);
    background-position: bottom;
    background-size: 100% 200%;
}

.btn-main button:disabled:hover{
    background-position: top;
    color: var(--secondary-text-color);
}

.btn-main button:hover{
    background-position: top;
    color: var(--secondary-text-color);
}

@media (max-width: 768px){
    .btn-main button{
        padding:0;
        padding: 0.35rem 1.5rem;
        font-size: 1.2em;
    }
}

@media (max-width:550px){
    .btn-main button{
        padding:0;
        padding: 0.35rem 0.5rem;
        font-size: 1em;
    }
}