.main-single-product {
    background-color: var(--background-color-2);
    padding-top: 3rem;
    font-family: var(--font-body-family);
    height: 100%;
    overflow-x: hidden;
}

.single-product-except-descrip {
    display: flex;
    align-items: flex-start;
    gap: 2.5rem;
}

/* .slick-list {
    position: static !important; 
    overflow: visible !important;
    margin: 0 !important; 
    padding: 0 !important;
    height: auto !important; 
    width: auto !important;
    z-index: auto !important; 
} */

.main-image-div {
    width: 50%;
    height: 100%;
}

.single-product-text {
    padding-top: 0.25rem;
    width: 50%;
    height: 100%;

}

.single-product-text-2-div {
    width: 100%;
}

.single-product-heading {
    font-size: 1.6em;
    font-weight: 600;
    color: var(--primary-text-color);
    margin: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-family: var(--font-heading-family);
    width: 90%;
}

.single-product-price-with-discount {
    color: var(--primary-text-color);
    font-size: 1.4em;
    font-weight: 700;
    width: 100%;
}

.single-product-discount-price-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    margin-top: -2.25rem;
}

.single-product-discount-percentage {
    color: var(--light-green-color);
    font-size: 1em;
    border-left: 1px solid var(--primary-text-color);
    padding-left: 0.25rem;
}

.single-product-orignal-price {
    color: var(--grey-text-color);
    font-size: 1em;
    text-decoration: line-through;
}


.single-product-descrp {
    color: var(--grey-text-color);
    font-size: 1em;
}

.cart-function-main-div {
    width: 75%;
    /* margin-top: -3.5rem; */
}

.cart-function {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin-bottom: 1rem; */
}

.cart-add-remove {
    display: flex;
    width: 35%;
    align-items: center;
    justify-content: center;
}

.add-sub-cart-btn {
    height: 51px;
    width: 51px;
    background-color: transparent;
    border: 1px solid var(--grey-text-color);
    color: var(--hover-color);
    font-size: large;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.add-sub-cart-btn:hover {
    background-color: var(--hover-color);
    color: var(--secondary-text-color);
}

.out-of-stock-text {
    font-size: 0.9em;
    font-style: italic;
    color: var(--hover-color);
}

.quantity-input:disabled {
    all: unset;
    width: 62px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey-text-color);
    background-color: transparent;
    box-sizing: border-box;
    color: var(--secondary-text-color);
    text-align: center;
    line-height: 1;
    font-size: 16px;
    padding: 0;
    margin: 0;
}

.quantity-input {
    text-align: center;
}

.cart-buy-now-btn {
    display: flex;
    flex-direction: column;
}

.buy-now-btn {
    color: var(--secondary-text-color);
    font-size: 1.2em;
    cursor: pointer;
    background-color: var(--hover-color);
    border: none;
    height: 50px;
    width: 100%;
    margin-top: 10px;
    text-align: center;
    font-weight: 700;
    line-height: 50px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.buy-now-btn:hover {
    background-color: red;
}

.buy-now-btn:disabled {
    background-color: var(--grey-text-color);
    color: var(--secondary-text-color);
    cursor: not-allowed;
}

.buy-now-btn:disabled:hover {
    background-color: gray;
}

.add-to-cart-btn {
    padding: 0;
    height: 50px;
    width: 200px;
}

.custom-size-btn {
    height: 50px;
    width: 130px;
}

.custom-size-btn:hover {
    background-position: top;
    color: var(--secondary-text-color);
}

.custom-size-btn-selected {
    margin-bottom: 0.5rem;
    width: 55%;
    height: 50px;
    background-color: var(--hover-color);
    color: var(--primary-text-color);
    text-transform: uppercase;
    border: none;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
}

.product-option {
    display: flex;
    align-items: center;
    gap: 20px;
}

.product-option-label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--primary-text-color);
}

.size-options {
    display: flex;
    gap: 10px;
    margin: 0.5rem;
    flex-wrap: wrap;
}

.size-option-btn {
    padding: 10px 20px;
    border: 1px solid var(--grey-text-color);
    background-color: transparent;
    color: var(--primary-text-color);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 4px;
}

.size-option-btn.selected {
    background-color: var(--hover-color);
    color: var(--secondary-text-color);
}

.size-option-btn:hover {
    background-color: var(--hover-color);
    color: var(--primary-text-color);
}

.size-option-btn:not(.selected):hover {
    background-color: var(--hover-color);
    color: var(--secondary-text-color);
}

.color-option-btn {
    border: 2px solid var(--grey-text-color);
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 100%;
    height: 25px;
    width: 25px;
}

.color-option-btn:hover {
    border: 2px solid var(--primary-text-color);
}

.color-option-btn.selected {
    border: 2px solid var(--secondary-text-color);
}

.product-description {
    padding: 2rem;
    font-size: 1em;
    color: var(--grey-text-color);
    width: 90%;
}

.product-description h3 {
    font-size: 1.2em;
    color: var(--grey-text-color);
}

.related-products-heading {
    font-size: 1.4em;
    font-weight: 600;
    text-align: center;
    color: var(--primary-text-color);
    text-transform: uppercase;
}

.measurement-form-div {
    margin: 0 auto;
    padding: 1rem 0rem;
}

.card-price-stock-single-product {
    cursor: pointer;
}

.single-product-size-chart {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-single-product-size-chart {
    width: 80%;
    height: auto;
}

.single-product-mini-description {
    text-align: left;
    width: 95%;
    color: var(--primary-text-color);
}

.single-product-mini-description.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.single-product-mini-description.expanded {
    display: block;
}

@media (max-width: 1100px) {
    .single-product-except-descrip {
        gap: 2.75rem;
    }

    .product-description h3 {
        font-size: 1em;
    }

    .single-product-heading {
        font-size: 1.2em;
    }

    .single-product-orignal-price {
        font-size: 1.2em;
    }

    .cart-add-remove {
        width: 40%;
    }

    .cart-function {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .quantity-input:disabled {
        width: 62px;
        text-align: center;
    }

    .add-sub-cart-btn {
        width: 51px;
    }

    .add-to-cart-btn {
        width: 150px;
    }

}

@media (max-width: 850px) {
    .main-single-product {
        margin: 0 auto;
    }

    .single-product-except-descrip {
        flex-direction: column;
        justify-content: center;
        gap: 0;
    }

    .single-product-heading {
        width: 100%;
    }

    .card-price-stock-single-product {
        margin: 0 auto;
    }

    .single-product-text {
        margin: 0 auto;
        padding: 0;
        width: 100%;
        text-align: center;
    }

    .main-single-product {
        flex-direction: column;
        align-items: center;
        padding: 0;
        padding-top: 40px;
    }

    .product-option {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .single-product-text {
        align-items: center;
        text-align: center;
        border-bottom: none;
    }

    .single-product-heading,
    .single-product-descrp,
    .single-product-orignal-price {
        margin-bottom: 1rem;
    }

    .main-image-div {
        width: 100%;
    }

    .single-product-heading {
        font-size: 1.2em;
        flex-wrap: wrap;
        text-align: center;
        padding: 0rem 1rem;
    }

    .cart-add-remove {
        width: 40%;
    }

    .cart-function-main-div {
        width: 55%;
        padding: 1rem;
        margin: 0 auto;
    }

    .cart-function {
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }

    .add-to-cart-btn,
    .custom-size-btn {
        width: 200px;
    }

    .buy-now-btn {
        width: 100%;
    }

    .single-product-discount-price-div {
        flex-direction: row;
        justify-content: center;
        gap: 6px;
    }
}

@media (max-width: 550px) {

    .main-product-image {
        width: 250px;
    }

    .single-product-img {
        width: auto;
        height: auto;
    }

    .single-product-text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 100%;
        text-align: center;
    }

    .main-product-image {
        width: 250x;
        height: 300px;
    }

    .size-options {
        display: flex;
        justify-content: center;
    }

    .color-option-btn {
        width: 25px;
        height: 25px;
        border-radius: 100%;
    }

    .cart-function-main-div {
        width: 90%;
    }

    .cart-function {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .cart-add-remove {
        width: 100%;
        margin-bottom: 1rem;
    }

    .add-to-cart-btn,
    .custom-size-btn {
        width: 164px;
    }

    .buy-now-btn {
        width: 100%;
    }

}