.thumbnail-slider {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden; /* Hide overflowing thumbnails */
  }
  
  .img{
    width: 20px;
    height: 20px;
    margin: 0px 0px 0px -4px;
  }
  .img2{
    width: 15px;
    height: 15px;
    margin: 2px 0 0 -3px;
  }
  
  
  .thumbnail-set{
    width: 40%;
    margin: 0 0 0 26%;
  }
  .scroll {
    display: flex;
    gap: 10px; /* Space between thumbnails */
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-behavior: smooth; /* Smooth scrolling for thumbnails */
    width: 100%; /* Full-width container for thumbnails */
    padding: 0 0 0 31px;
  }
  
  .thumbnail {
    flex: 0 0 auto; /* Ensure fixed size for each thumbnail */
    width: 65px;
    height: 65px;
    border: 2px solid transparent;
    cursor: pointer;
  }
  .scroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
  }
  
  .thumbnail-slider-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* background:var(--hover-color); */
    color: rgb(26, 25, 25);
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 16px;
    padding: 6px 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .thumbnail-slider-nav.left {
    left: 0;
  }
  
  .thumbnail-slider-nav.right {
    right: 0;
  }
  
  .thumbnail-slider-nav:disabled {
    visibility: hidden;
  }
  
  /* Optional: Style for the scroll bar */
  .scroll::-webkit-scrollbar {
    height: 8px; /* Height of the scrollbar */
  }
  
  .scroll::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar thumb color */
    border-radius: 4px; /* Rounded corners for scrollbar thumb */
  }
  
  .scroll::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker thumb color on hover */
  }
  
  
  
  .thumbnail.active {
    border-radius: 6px;
    padding: 6px;
    border-color: rgb(107, 107, 107); /* Highlight active thumbnail */
  }
  
  .thumbnail-slider-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #c7242e;
    /* color: rgb(255, 0, 0); */
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .thumbnail-slider-nav.left {
    left: 0;
  }
  
  .thumbnail-slider-nav.right {
    right: 0;
  }
  
  .thumbnail-slider-nav:disabled {
    visibility: hidden;
  }
  
  .thumbnail img,
  .thumbnail video {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  /* Main image adjustments */
  .img-slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slider-wrapper {
    width: 100%;
    max-width: 80%; /* Adjust the size for mobile */
    overflow: hidden;
  }
  
  .slide {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .slide.active {
    display: block;
    opacity: 1;
  }
  
  .main-product-image {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    border-radius: 8px;
  }
  
  .video-img-slider {
    width: 100%;
    border-radius: 8px;
  }
  
  /* Navigation buttons */
  .prev-btn,
  .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background:var(--hover-color);
    color: rgb(255, 0, 0);
    height: 30px;
    width: 30px;
    border: none;
    border-radius: 16px;
    padding: 6px 10px;
    cursor: pointer;
    z-index: 10;
  }
  /* .next-btn:hover {
    background-color: var(--hover-color);
    color: var(--navbar-background);
  }
  .prev-btn:hover {
    background-color: var(--hover-color);
    color: var(--navbar-background);
  } */
  
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .thumbnail-set{  
      margin: 0 0 0 0;
      width: 100%;
    
    }
    .thumbnail-slider{
      display: flex;
      justify-content: center;
          align-items: center;
          position: relative;
          overflow: hidden;
       
  
    }
    
    .thumbnail img,
    .thumbnail video {
      width: 40px;
      height: 40px;
    }
  
    .main-product-image {
      max-width: 90%;
    }
  
    .prev-btn,
    .next-btn {
      padding: 4px 8px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .thumbnail img,
    .thumbnail video {
      width: 50px;
      height: 50px;
    }
  
    .main-product-image {
      max-width: 100%;
    }
  
    .prev-btn,
    .next-btn {
      display: none; /* Hide navigation buttons on very small screens */
    }
  }
  