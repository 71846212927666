.contact-div-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-color-2);
    padding: 2rem;
    padding-top: 8rem;
    padding-bottom: 3rem;
    font-family: var(--font-body-family);
}


.contact-text-divs {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey-text-color);
    width: 32%;
    height: 200px;
    cursor: pointer;
}

.contact-text-divs div{
    width: 80%;
    text-align: center;
}

.svg-contact{
    font-size: 38px;
    padding: 0.5rem;
    border-radius: 100%;
    background-color: grey;
    text-align: center;
}

.contact-heading{
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: 650;
    color: var(--primary-text-color);
    font-family: var(--font-heading-family);
}

.contact-text-divs p{
    color: var(--grey-text-color);
    font-size: 1em;
    text-wrap: wrap;
}

.contact-text-divs:hover{
    border: 1px solid var(--hover-color);
}

.contact-text-divs:hover .svg-contact{
    background-color: var(--hover-color);
    color: var(--primary-text-color);
}

.contact-text-divs:hover .contact-heading{
    color: var(--hover-color);
}

@media (max-width:768px){
    .contact-div-main{
        flex-direction: column;
    }

    .contact-text-divs{
        width: 90%;
        margin-bottom: 0.75rem;
    }
}