.shopping-page-product-filter{
    display: flex;
    align-items: flex-start;
    gap: 0px;
    background-color: var(--background-color-2);
    padding-top: 6rem;
}

/* 
@media (max-width: 768px){
    .shopping-page-product-filter{
        flex-direction: column;
    }
} */

@media (max-width: 550px){
    .shopping-page-product-filter{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}