/* Wrapper for main and zoomed images */
.zoom-wrapper {
  display: flex;
  gap: 20px;
  /* Space between the main and zoomed images */
  max-width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}
.main-product-image {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 8px;
}


/* Main image container */


/* Main image styling */
.main-image {
  cursor: zoom-in;
  height: 545px;
  width: 143%;
  object-fit: cover;
  margin: 0 auto;
  max-width: 545px;
}

/* Zoomed image container */

.zoomed-image {
  position: absolute;
  /* or fixed depending on your requirement */
  z-index: 1;
  /* Set to a lower value than text */
  width: 620px;
  height: 600px;
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: center;
  border: 1px solid #ddd;
  margin: 0 0 0 90%;

}

@media (max-width: 850px) {
  .zoomed-image {
    display: none;
  }
  .main-product-image {
    max-width: 100%;
    height: 245px;
    margin: 0 auto;
    border-radius: 8px;
  }

 

  .image-container {
    position: relative;
    width: auto;
    padding: 2px;
    height: 345px;
    max-width: 100%;
  }
}