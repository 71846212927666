.main-checkoutform {
    display: flex;
    justify-content: flex-start;
    /* color: var(--primary-text-color); */
    padding-left: 3rem;
    width: 100%;
}

.checkout-form{
    width: 90%;
}

.checkoutform-big-input{
    background: transparent;
    border: 1px solid var(--grey-text-color);
    height: 35px;
    padding-left: 0.75rem;
    margin-bottom: 1rem;
    width: 100%; 
    box-sizing: border-box;
    color: var(--grey-text-color);
    font-family: var(--font-body-family);
}

.checkoutform-big-input-required{
    background: transparent;
    border: 1px solid var(--hover-color);
    height: 35px;
    padding-left: 0.75rem;
    margin-bottom: 1rem;
    width: 100%; 
    box-sizing: border-box;
    /* color: var(--grey-text-color); */
    color: var(--hover-color);
}

.checkoutform-big-input::placeholder , .checkout-normal-input::placeholder{
    color: var(--grey-text-color);
}

.checkoutform-double-input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.checkout-normal-input{
    width: 49%;
    margin-bottom: 1rem;
    background: transparent;
    border: 1px solid var(--grey-text-color);
    height: 35px;
    color: var(--grey-text-color);
    padding-left: 0.75rem;
}

.checkout-normal-input-required{
    width: 49%;
    margin-bottom: 1rem;
    background: transparent;
    border: 1px solid var(--hover-color);
    height: 35px;
    padding-left: 0.75rem;
    color: red;
}

.checkout-normal-input-required::placeholder , .checkoutform-big-input-required::placeholder , .checkoutform-big-select-required{
    color: red;
}

.checkoutform-big-select{
    width: 100%;
    background: transparent;
    color: var(--grey-text-color);
    margin-bottom: 1rem;
    height: 35px;
    border: 1px solid var(--grey-text-color);
    padding-left: 0.75rem;
}

.checkoutform-select{
    width: 49%;
    margin-bottom: 1rem;
    background: transparent;
    border: 1px solid var(--grey-text-color);
    height: 35px;
    color: var(--grey-text-color);
    padding-left: 0.75rem;
}

.checkoutform-big-select-required{
    width: 100%;
    background: transparent;
    color: red;
    margin-bottom: 1rem;
    height: 35px;
    border: 1px solid var(--hover-color);
}


.shipping-method-div {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border: 1px solid var(--grey-text-color);
    width: 100%;
    padding: 0.5rem;
}

.checkout-method-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.checkout-method-div-label, .checkout-method-div-label-selected {
    padding: 1rem;
    border: 1px solid var(--grey-text-color);
    width: 100%;
    cursor: pointer;
    margin-bottom: 10px;
    box-sizing: border-box;
    color: var(--primary-text-color);
}

.checkout-method-div-label-selected {
    background-color: var(--hover-color);
}

.billing-delievery-form{
    background-color: var( --navbar-background);
    padding: 1rem;
}  

/* Responsive Styles */
@media (max-width: 768px) {
    .main-checkoutform {
        padding: 10px;
        justify-content: center;
    }

    .checkout-method-div-label, .checkout-method-div-label-selected {
        font-size: 0.9rem;
        padding: 0.8rem;
    }

    .checkout-normal-input , .checkout-normal-input-required , .checkoutform-select {
        width: 100%;
        margin-bottom: 1rem;
        height: 40px;
    }

    .checkoutform-big-input ,  .checkoutform-big-input-required {
        width: 100%;
        margin-bottom: 1rem;
        height: 40px;
    }

   
}

@media (max-width: 480px) {
    /* .checkoutform-big-input, .checkoutform-double-input input {
        height: 30px;
        padding-left: 0.3rem;
    } */

    .shipping-method-div {
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem 0;
    }

    /* .checkout-method-div-label, .checkout-method-div-label-selected {
        font-size: 0.8rem; 
        padding: 0.6rem;
    } */
}
