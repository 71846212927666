.sub-cart-wishlist {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 10px;
    height: 65%;
    overflow-y: auto;
}

.sub-cart-wishlist::-webkit-scrollbar {
    width: 5px;
    background: #555;
}

.sub-cart-wishlist::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.total-price-wishlist{
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 1px solid var(--primary-text-color);
    padding: 1rem;
}

@media (max-width: 550px){
    .sub-cart-wishlist {
        height: 80%;
    }

    .total-price-wishlist{
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        padding: 0.5rem;
        width: 100%;
        border-top: 1px solid var(--primary-text-color);
    }
}