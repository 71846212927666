body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: var(--font-body-family);
    background: var(--background-color-2);
}

.body::-webkit-scrollbar {
    width: 8px;
    background: #555;
}

.body::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}


:root {
    --navbar-background: #1A1A1A;
    --navbar-toggle-menu-background: #1A1A1A;
    --secondary-text-color: #fff;
    --background-color-2: #0E0E0E;
    --primary-text-color: #e0e0e0;
    --hover-color: #c7242e;
    --text-red-color: #ec213a;
    --grey-text-color: #999;
    --green-color: green;
    --light-green-color: lightgreen;
    --font-heading-family: 'Montserrat Alternates', sans-serif;
    --font-body-family: 'Nunito', sans-serif;


    /* sky blue theem */
    /* --navbar-background: #f7f7f7;
    --navbar-toggle-menu-background: #f9f9f9;
    --secondary-text-color: #333333;
    --background-color-2: #f0f0f0;
    --primary-text-color: #1a1a1a;
    --hover-color: #4682b4;
    --text-red-color: #4682b4;
    --grey-text-color: #666666;
    --green-color: #28a745;
    --light-green-color:#5a9a68;
    --font-heading-family: 'Oswald', sans-serif;
    --font-body-family: 'Source Sans Pro', sans-serif; */


}

.body-LightMode {
    --navbar-background: #333;
    --navbar--color: #fff;
}

.body-DarkMode {
    --navbar-background: #333;
    --navbar--color: #fff;
}