.filter-sidebar {
    min-width: 20%;
    max-width: 20%;
    min-height: 100%;
    padding: 0.5rem;
    padding-top: 3rem;
    background-color: var(--background-color-2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 50px;
    color: var(--primary-text-color);
    font-family: var(--font-body-family);
}

.filter-section {
    padding: 0 0.5rem;
}

.filter-heading {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--secondary-text-color);
    text-transform: uppercase;
    padding-bottom: 5px;
    border-bottom: 1px solid #666;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-heading-family);
}

.arrow-up::after {
    content: '^';
    font-size: 0.8em;
    margin-left: 5px;
}

.arrow-down::after {
    content: 'v';
    font-size: 0.6em;
    margin-left: 5px;
}

.filter-option {
    /* margin-bottom: 10px; */
    display: flex;
    align-items: center;
}

.filter-option label {
    font-size: 1.2em;
    color: #666;
}

.filter-option label:hover {
    color: var(--hover-color);
}

.filter-section-p {
    font-size: 1em;
    color: #666;
    cursor: pointer;
    margin-top: 0.5rem;
}

.filter-section-p:hover {
    color: var(--hover-color);
    text-decoration: underline;
}

.filter-section-p.active {
    color: var(--hover-color);
}

.price-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.avaliability-para {
    color: var(--grey-text-color);
    cursor: pointer;
}

.avaliability-para:hover {
    color: var(--hover-color);
    text-decoration: underline;
}

.avaliability-para-selected {
    color: var(--hover-color);
    cursor: pointer;
}

.avaliability-para-selected:hover {
    text-decoration: underline;
}

.price-values {
    display: flex;
    justify-content: center;
}

.filter-btn {
    margin-top: 0.5rem;
}

.mobile-filter-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
    background-color: var(--hover-color);
    border: none;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-weight: 600;
    font-size: 1.4em;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer;
    color: var(--primary-text-color);
    display: none;
    /* opacity: 0; */
}

.reset-filters-btn{
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1em;
    margin-top: 1.5rem;
    font-weight: 600;
    cursor: pointer;
}

.reset-filters-btn:hover{
    text-decoration: underline;
}

@media (max-width: 850px) {
    .filter-sidebar {
        min-width: 20%;
        max-width: 20%;
    }

    .price-range {
        flex-direction: column;
        align-items: flex-start;
    }

    .price-range input {
        width: 80%;
    }
}

@media (max-width: 550px) {
    .price-range input {
        width: 100%;
    }

    .filter-sidebar-open {
        width: 50%;
        height: 100%;
        padding: 1rem;
        background-color: var(--background-color-2);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 0;
        right: 0;
        color: var(--primary-text-color);
        font-family: var(--font-body-family);
        z-index: 2000;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
    }

    .close-icon-product-filter{
        position: relative;
        left: 95%;
        font-size: 1.2em;
        margin-bottom: 0.75rem;
        cursor: pointer;
    }

    .close-icon-product-filter:hover{
        color: var(--hover-color);
    }

    .filter-sidebar {
        display: none;
    }

    .mobile-filter-button {
        display: block;

    }

    .filter-section{
        padding: 1rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 350px){
    .filter-sidebar-open {
        width: 60%;
    }
}