.container {
    width: 100%;
    margin: auto;
    padding:2rem ;
    text-align: justify;
}

.header {
    text-align: center;
    margin-bottom: 50px;
}

.header h1 {
    font-size: 2.5em;
    color: var(--primary-text-color);
    font-family: var(--font-heading-family );
    text-transform: uppercase;
}

.header p {
    font-size: 1.2em;
    color: #777;
}

.section {
    margin-bottom: 30px;
}

.section h2 {
    font-size: 1.8em;
    color: var(--secondary-text-color);
    margin-bottom: 15px;
    text-transform: uppercase;
}

.section p, .section ul {
    font-size: 1em;
    color: var(--primary-text-color);
    line-height: 1.6;
    font-weight:100;
}

.section ul {
    list-style-type: disc;
    padding-left: 50px;
}

.section ul li {
    margin-bottom: 10px;
}

.section table {
    border-collapse: collapse;
}

.section table tr,td,th{
    border: 1px solid var(--grey-text-color);
    color: var(--primary-text-color);
    padding:  1rem;
}

.section table th{
    font-family: var(--font-heading-family);
}

.section table th:hover{
    background-color: var(--hover-color);
}

.deliery-refund-policy{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-top: -2rem;
    padding-left: 1rem;
}

.deliery-refund-policy p{
    cursor: pointer;
}

.deliery-refund-policy p:hover {
    text-decoration: underline;
}