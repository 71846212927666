.main-category-component {
    background-color: var(--background-color-2);
    padding-top: 0.5rem;
    width: 100%;
}

.main-category-heading {
    text-align: center;
    font-weight: bolder;
    font-size: 2.2em;
    color: var(--primary-text-color);
    text-transform: uppercase;
    font-family: var(--font-heading-family);
}

.blog-container-category {
    display: grid;
    gap: 10px;
    padding: 1rem;
}



.large-blogs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.large-blog {
    position: relative;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    height: 450px;
    width: 100%;
}

.small-blogs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.small-blog {
    position: relative;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    height: 325px;
    width: 100%;
}

.large-blog::before,
.small-blog::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.text-div-category {
    position: absolute;
    z-index: 2;
    top: 45%;
    left: 8%;
    color: var(--primary-text-color);
}


.category-image-heading {
    color: var(--secondary-text-color);
    font-weight: 700;
    font-size: 1.6em;
    z-index: 2;
    font-family: var(--font-heading-family);
    text-transform: uppercase;
}

.category-image-description {
    color: var(--primary-text-color);
    font-size: 0.9em;
}

.category-btn {
    height: 32px;
    width: 125px;
    background: linear-gradient(to bottom, var(--hover-color) 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: bottom;
    border: none;
    background-color: var(--secondary-text-color);
    font-size: 17px;
    color: var(--grey-text-color);
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-position 0.4s ease-in-out, color 0.4s ease-in-out;
    cursor: pointer;
    z-index: 1;
}

.category-btn:hover {
    background-position: top;
    color: var(--primary-text-color);
}

@media (max-width: 768px) {
    .blog-container {
        grid-template-columns: 1fr;
    }

    .large-blog,
    .small-blog {
        height: 300px;
    }

    .category-btn {
        font-size: 1em;
        width: 120px;
        line-height: 0;
        padding: 0;
    }
}

@media (max-width: 550px) {
    .main-category-heading {
        font-size: 1.4em;
    }

    .category-image-heading {
        font-size: 1.2em;
    }

    .text-div-category {
        bottom: 10%;
        left: 6%;
        color: var(--primary-text-color);
        width: 60%;
    }

    .category-btn {
        font-size: 1em;
        width: 100px;
        line-height: 0;
        padding: 0;
    }

    .large-blogs {
        grid-template-columns: repeat(1, 1fr);
    }

    .small-blogs {
        grid-template-columns: repeat(1, 1fr);
    }
}