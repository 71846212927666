.order-status {
  width: 100%;
  max-width: 500px; 
  margin: 50px auto;
  cursor: pointer;
}

.progress-bar {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  min-width: 100px; 
  box-sizing: border-box;
}

.step .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--grey-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  position: relative;
  z-index: 1;
}

.step.completed .circle {
  background-color: #4CAF50;
}

.step.active .circle {
  background-color: #4CAF50;
}

.step.cancel .circle {
  background-color: red;
}

.step .label {
  margin-top: 5px;
  color: var(--primary-text-color);
  font-size: 14px;
}

/* Progress bar line */
.progress-bar::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: lightgray;
  z-index: 0;
  position: absolute;
  top: 15px;
}

/* Connector lines between steps */
/* .step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% / 4);
  height: 4px;
  background-color: lightgray;
  z-index: 0;
  /* transform: translateX(-50%); 
} */

/* .step.completed ~ .step::after {
  background-color: #4CAF50;
}

.step.current ~ .step::after {
  background-color: #8BC34A;
} */

.step:first-child::after,
.step:last-child::after {
  content: none;
}



/* Responsive adjustments */
@media (max-width: 600px) {
  .step {
    flex: 1 1 25%; 
    min-width: auto; 
    margin-bottom: 20px; 
  }

  .step .circle {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  .step .label {
    font-size: 12px;
  }
}

@media (max-width:550px){
  .progress-bar::before{
    top: 10px;
  }
}